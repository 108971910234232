<template>
    <div v-bind="$attrs">
        <div style="line-height: 1;" @mouseover="showPinned = true" @mouseleave="showPinned = false">
            <template v-if="!_comment.IsImage">
                <div class="font-n2 fw-semibold">
                    <div class="d-flex align-items-center gap-1">
                        <div class="text-truncate" v-person-hover="_comment.CreatedBy_ID">
                            {{ _comment.CreatedBy }}
                        </div>
                        <div class="flex-shrink-0">
                            {{ $formatDate(_comment.Created) }}
                        </div>
                        <template v-if="!isMobile && shouldShowUpdatedInfo">
                            <div class="italic">
                                - {{ $t("Updated") }} {{ $formatDate(_comment.Updated) }}
                            </div>
                        </template>
                        <template v-if="(showActions || allowEdit) && !_comment.IsImage && _comment.CreatedBy_ID">
                            <div class="flex-shrink-0 ms-2">
                                <div class="dropdown d-inline">
                                    <a
                                        class="font-2 text-black"
                                        data-bs-toggle="dropdown"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        style="cursor: pointer; width: 1.5em; height: 1.5em; margin: -0.75em; padding: 0.75em;"
                                    >
                                        <i class="bi bi-chevron-down"></i>
                                    </a>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">      
                                        <template v-if="canAdministrateItem">
                                            <button class="dropdown-item" @click="editItem" v-if="!_comment.Recipients">{{ $t("Edit") }}</button>   
                                            <button class="dropdown-item" @click="deleteComment" v-if="!_comment.Recipients">{{ $t("Delete") }}</button>
                                            <button class="dropdown-item" @click="sendReminder" v-if="_comment.AssignedTo_ID && props.sendReminderProcedure">{{ $t("Send Reminder") }}</button>
                                            <div class="dropdown-divider" v-if="!_comment.Recipients"></div>
                                        </template>                                        
                                        <button class="dropdown-item" @click="newItem({ type:'action' })">{{$t("Add Action / Comment")}}</button>   
                                        <button class="dropdown-item" @click="newItem({ type:'verification' })" v-if="props.dataobject.fields['IsVerification']">{{$t("Add Verification")}}</button>
                                        <button class="dropdown-item" @click="showEmailDlg">{{$t("New Email")}}</button>
                                        <OFileUploadButton :dataObject="props.dataobject" :beforeUpload="() => beforeUpload(_comment)" >
                                            <button class="dropdown-item">{{$t("Upload File(s)")}}</button>
                                        </OFileUploadButton>
                                        <button class="dropdown-item" @click="addFromArchive">{{$t("Add Image From Archive")}}</button>
                                        <span v-if="props.dataobject.fields['Pinned']">
                                            <button class="dropdown-item" @click="pinComment(true)" v-if="_comment.Pinned == null">{{ $t("Pin") }}</button>
                                            <button class="dropdown-item" @click="pinComment(false)" v-else>{{ $t("Unpin") }}</button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                    <template v-if="isMobile && shouldShowUpdatedInfo">
                        <div class="mt-1 italic">
                            {{ $t("Updated") }} {{ $formatDate(_comment.Updated) }}
                        </div>
                    </template>
                </div>
            </template>
        </div>
        <div v-if="!isEditing">
            <div v-if="_comment.Recipients != null"><i class="bi bi-envelope me-1"></i>{{$t("Email To")}}: {{_comment.Recipients}}<span></span></div>
            <div>{{_comment.Title}}</div>
            <div style="line-height: 1.2;white-space: pre-wrap;margin-top: 0.15rem; word-wrap: break-word;" v-url-to-anchor-tag="_comment.Comment"></div>
            <div v-if="_comment.FileRef">
                <OAttachments v-if="showAttachmentsInSpotlight || _comment.IsImage" :dataObject="props.dataobject" showFileNames imageEditor :filterCallback="filterForComment" :overrideIndex="index"></OAttachments>
                <a v-if="!showAttachmentsInSpotlight && !_comment.IsImage" :href="_comment.fileDownloadPath" >{{_comment.FileName}}</a>
            </div>
        </div>
        <div v-else-if="isEditing || isNew" class="mt-1 row">
            <div class="form-group col-12">
                <OTextArea v-model="_comment.Comment" class="border d-inline-block p-1 bg-white form-control" ref="textArea" autoGrow noResize />
            </div>
            <div class="form-group col-lg-3 col-6">
                <label for="content-first-released">{{ $t("Assigned To") }}</label>
                <OPersonsLookup 
                    :bind="sel => { _comment.AssignedTo_ID = sel.ID, _comment.AssignedTo = sel.Name; _comment.Role_ID = null; _comment.Role = null; setMobile(sel) }"
                    v-model="_comment.AssignedTo" 
                    :dataObject="_personDataObject"
                    class="form-control form-control-sm">
                        <template #toolbarActions v-if="showRestrictToPersonWithAccess">
                            <OToolbarAction :text="(props.isClientWorkspace ? $t('Include All Persons With Access') : $t('Include All Persons'))" v-model="restrictPersonsLkpToPersonsWithAccess" />
                        </template>
                    </OPersonsLookup>
            </div>
            <template v-if="props.rolesDataObject">
                <div class="form-group col-lg-3 col-6" v-if="props.dataobject.fields['Role_ID']">
                    <label>{{ $t("Role") }}</label>
                    <ORolesLookup 
                        class="form-control form-control-sm" 
                        :dataObject="props.rolesDataObject"
                        :whereClause="`Closed IS NULL ${setRolesWhereClause()}`"
                        forceReloadOnOpen
                        :bind="selected => {_comment.Role_ID = selected.ID, _comment.Role = selected.Title; _comment.AssignedTo_ID = null, _comment.AssignedTo = null }"
                        v-model="_comment.Role">
                        <template #target="{target}">
                            <input :ref="target" v-model="_comment.Role" class="form-control form-control-sm" />
                        </template>
                    </ORolesLookup>
                </div>
            
            </template>
            <template v-else>
                <div class="form-group col-lg-3 col-6" v-if="props.dataobject.fields['Role_ID']">
                    <label>{{ $t("Role") }}</label>
                    <ORolesLookup 
                        class="form-control form-control-sm" 
                        viewName="sviw_System_Roles"
                        :whereClause="`Closed IS NULL ${setRolesWhereClause()}`"
                        forceReloadOnOpen
                        :bind="selected => {_comment.Role_ID = selected.ID, _comment.Role = selected.Title; _comment.AssignedTo_ID = null, _comment.AssignedTo = null }"
                        v-model="_comment.Role">
                        <template #target="{target}">
                            <input :ref="target" v-model="_comment.Role" class="form-control form-control-sm" />
                        </template>
                    </ORolesLookup>
                </div>
            </template>

            <div class="form-group col-lg-3 col-6" v-if="_comment.AssignedTo_ID || _comment.Role_ID">
                <label for="due-date-input">{{ $t("Due Date") }}</label>
                <ODatePicker id="due-date-input" v-model="_comment.DueDate" class="form-control form-control-sm" format="Short Date" date />
            </div>

            <div class="form-group col-lg-3 col-6" v-if="_comment.AssignedTo_ID != null && assignedToHasMobileNo">
                <div class="form-check mt-4">
                    <input class="form-check-input" type="checkbox" id="flexCheckDefault" v-model="_comment.AskForResponseViaSMS">
                    <label class="form-check-label" for="flexCheckDefault">
                        {{ $t("Ask for response via SMS") }}
                    </label>
                </div>
            </div>

            <div>
                <OActionSave class="btn btn-sm btn-link pt-0" :row="_comment" :disabled="isVerification && !(_comment.AssignedTo_ID || _comment.Role_ID)">{{ $t("Save") }}</OActionSave>
                <button class="btn btn-sm btn-link ms-1 pt-0" :row="_comment" @click="doCancel()" :dataObject="props.dataobject">{{ $t("Cancel") }}</button>
            </div>
        </div>  

        <div v-if="_comment.AssignedTo_ID && _comment.Role_ID == null && !_comment.IsVerification && !isEditing">
            <div class="form-group" v-if="!_comment.Completed">
                <span class="text-primary">{{ $t("Action Assigned To") }}: 
                <span v-if="disableLookupHovers">{{_comment.AssignedTo}}</span>
                <span v-else v-person-hover="_comment.AssignedTo_ID">{{_comment.AssignedTo}}</span>
                <template v-if="_comment.DueDate">, {{ $t("Due Date") }}: {{ $formatDate(_comment.DueDate, "Short Date") }}</template></span>                
                <OTextArea class="form-control form-control-sm" v-model="_closeoutComment" :placeholder="`${$t('Type comment here to close action...')}`" autoGrow noResize :rows="1" />
                <div>
                    <template v-if="isMobile">
                        <OActionSaveCancel class="mt-2" :row="_comment" field="CloseOutComment" />
                    </template>
                    <template v-else>
                        <OActionSave class="btn btn-sm btn-link" :row="_comment" :dataObject="props.dataobject">{{ $t("Save") }}</OActionSave>
                        <OActionCancel class="btn btn-sm btn-link ms-1" :row="_comment"  :dataObject="props.dataobject">{{ $t("Cancel") }}</OActionCancel>
                    </template>
                </div>
            </div>

            <div v-if="_comment.Completed" class="ms-3 mt-2">
                <span class="text-primary signature" >{{ $t("Action Completed By") }} <span v-if="disableLookupHovers">{{_comment.CompletedBy}}</span><span v-else v-person-hover="_comment.CompletedBy_ID">{{_comment.CompletedBy}}</span> {{ utils.formatDate(_comment.Completed) }}</span>
                <div class="dropdown d-inline" v-if="canEditCompletedActionResponses">
                    <template v-if="allowEdit">
                        <button class="btn p-0 px-2 border-0" type="button" data-bs-toggle="dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height: 17px;vertical-align: text-top;">   
                            <i class="bi bi-chevron-down"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">      
                            <button class="dropdown-item" @click="isEditingActionResponse = true">{{ $t("Edit") }}</button>
                        </div>
                    </template>
                </div>
                <div v-if="!isEditingActionResponse" class="multiline-comment">
                    <i>{{_closeoutComment}}</i>
                </div>
                <div v-else>                    
                    <OTextArea class="form-control form-control-sm" v-model="_closeoutComment" :placeholder="`${$t('Type comment here to close action...')}`" autoGrow noResize :rows="1" />
                    <div>
                        <template v-if="isMobile">
                            <OActionSaveCancel class="mt-2" :row="_comment" />
                        </template>
                        <template v-else>
                            <OActionSave class="btn btn-sm btn-link" :row="_comment" :dataObject="props.dataobject">{{ $t("Save") }}</OActionSave>
                            <OActionCancel class="btn btn-sm btn-link ms-1" :row="_comment" enabledWithoutChanges :dataObject="props.dataobject">{{ $t("Cancel") }}</OActionCancel>
                        </template>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="(_comment.IsVerification && !_comment.isNewRecord && !isEditing) || isEditingVerificationResponse">
            <span class="text-primary" v-if="_comment.Role_ID" :class="{'text-decoration-line-through':_comment.Completed}">
                {{$t("Role Responsible")}} : 
                <span v-if="!_comment.OrgUnit_ID || disableLookupHovers">{{_comment.Role}}</span>
                <span v-else v-role-persons-hover="{ roleId: _comment.Role_ID, orgUnitId: _comment.OrgUnit_ID }">{{_comment.Role}}</span>
            </span>
            <span class="text-primary" v-if="!_comment.Completed && !_comment.Role_ID">
            {{ $t("Assigned To") }}: 
            <span v-if="disableLookupHovers">{{_comment.AssignedTo}}</span>
            <span v-else v-person-hover="_comment.AssignedTo_ID">{{_comment.AssignedTo}}</span>
            </span>
            <span v-if="_comment.DueDate && !_comment.Completed">, {{ $t("Due Date") }}: {{ $formatDate(_comment.DueDate, "Short Date") }}</span>
            <div v-if="((!_comment.Approved && !_comment.Rejected) || isEditingVerificationResponse) && _comment.CanCompleteVerification">                
                <OTextArea class="form-control form-control-sm" v-model="_closeoutComment" :placeholder="`${$t('Type here to complete...')}`" autoGrow noResize :rows="1" />
                <div class="mt-2">
                    <button class="btn btn-sm btn-primary me-2" @click="approveVerification" >{{$t("Approve")}}</button>
                    <button class="btn btn-sm btn-outline-secondary me-3" @click="rejectVerification" >{{$t("Reject")}}</button>
                    <OActionCancel class="btn btn-sm btn-outline-secondary" :row="_comment" v-if="_comment.hasChanges || isEditingVerificationResponse" :enabledWithoutChanges="isEditingVerificationResponse">{{$t("Cancel")}}</OActionCancel>
                </div>
            </div>
            <template v-if="_comment.Completed && !isEditingVerificationResponse">
                <span class="text-muted" :class="{'ms-1': !_comment.Completed}">
                    &nbsp<template v-if="_comment.Approved">{{$t("Approved by")}}</template><template v-else>{{$t("Rejected by")}}</template> <span v-person-hover="_comment.CompletedBy_ID">{{_comment.CompletedBy}}</span> {{ utils.formatDate(_comment.Completed) }}
                </span>
                <template v-if="allowEdit">
                    <button v-if="_comment.CanCompleteVerification" class="btn p-0 px-2 border-0" type="button" data-bs-toggle="dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height: 17px;vertical-align: text-top;">   
                        <i class="bi bi-chevron-down"></i>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <button class="dropdown-item" @click="isEditingVerificationResponse = true">{{$t("Edit")}}</button>
                    </div>
                </template>
                <div class="multiline-comment">&nbsp<i>{{_closeoutComment}}</i></div>
            </template>
        </div>

        <div v-if="!_comment.IsVerification && _comment.Role_ID != null && _comment.AssignedTo_ID == null && !_comment.isNewRecord && !isEditing">
            <span class="text-primary" :class="{'text-decoration-line-through':_comment.Completed}">
                {{$t("Role Responsible")}} : <span v-if="!_comment.Role_ID || disableLookupHovers">{{_comment.Role}}</span><span v-else v-role-persons-hover="{ roleId: _comment.Role_ID, orgUnitId: _comment.OrgUnit_ID }">{{_comment.Role}}</span>
            </span>
            <template v-if="!comment.Completed">                
                <OTextArea class="form-control form-control-sm" v-model="_closeoutComment" :placeholder="`${$t('Type comment here to close task...')}`" autoGrow noResize :rows="1" />
                <div>
                    <OActionSave class="btn btn-sm btn-link" :row="_comment" :dataObject="props.dataobject">{{ $t("Save") }}</OActionSave>
                    <OActionCancel class="btn btn-sm btn-link ms-1" :row="_comment" enabledWithoutChanges :dataObject="props.dataobject">{{ $t("Cancel") }}</OActionCancel>
                </div>
            </template>
            <template v-else>
                <div class="multiline-comment ms-1">
                    <i>{{_closeoutComment}}</i>
                </div>
                <span class="ms-1 text-muted" >{{ $t("Completed By") }} {{_comment.CompletedBy}} {{ utils.formatDate(_comment.Completed) }}</span>
            </template>
        </div>

        <Journal 
            v-if="!isNew" 
            :ownerid='_comment[props.ownerField]' 
            :ownerField="ownerField"
            ref="journalRef" 
            :parentCommentId="comment[parentCommentParentField]"
            :parentCommentField="parentCommentField"
            :parentCommentParentField="parentCommentParentField"
            :disableLookupHovers="disableLookupHovers"
            :allowEdit="allowEdit" 
            :dataobject="props.dataobject" 
            :class="{'ms-3 mt-2':parentCommentId}" 
            :personDataObject="props.personDataObject" 
            :sendEmailProcedure="sendEmailProcedure"
            :isClientWorkspace="props.isClientWorkspace"
        />
    </div>

    <OModal ref="imageArchiveModal">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <div>
                        <h4 class="modal-title" id="staticBackdropLabel">
                            {{ $t("Add Image From Archive") }}
                        </h4>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="h-100 d-flex flex-column justify-content-between">
                        <OImageBrowser @addImages="addImages" style="height: 70vh; overflow-y: auto;" />
                    </div>
                </div>
            </div>
        </div>
    </OModal>
    <OModal ref="sendReminderModal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <div>
                        <h4 class="modal-title" id="staticBackdropLabel">
                            {{ $t("Send Reminder") }}
                        </h4>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <OTextArea style="width:100%;min-height:350px" spellcheck="false" v-model="reminderText" class="border d-inline-block p-2 bg-white  form-control form-control-sm"  autoGrow   />
                </div>
                <div class="modal-footer">
                    <button class="btn btn-sm btn-primary" @click="sendReminderConfirm">{{ ("Send") }}</button>
                    <button class="btn btn-sm btn-outline-primary" data-bs-dismiss="modal">{{ ("Cancel") }}</button>
                </div>
            </div>
        </div>
    </OModal>
</template>

<script setup>
    import { computed, ref, onMounted, onUnmounted, watch } from 'vue'; 
    import { utils } from 'o365-utils';   
    import { OPersonsLookup } from 'o365-system-lookups';
    import Journal from './Journal.vue';
    import { OFileUploadButton } from 'o365-fileupload';
    import { ORolesLookup } from 'o365-system-lookups';
    import { OAttachments } from 'o365-fileupload';

    import OActionSave from './Actions.Save.vue';
    import OActionCancel from './Actions.Cancel.vue';
    import OImageBrowser from './ImageBrowser.vue';

    import { alert } from 'o365-vue-services';

    import vUrlToAnchorTag from "o365.vue.directive.urlToAnchorTag.ts";
    import vPersonHover from 'system.libraries.vue.directive.personHover.ts';

    import { getOrCreateProcedure, userSession } from 'o365-modules';

    import vRolePersonsHover from "system.libraries.vue.directive.orgUnitRolesHover.ts";
    
    import { isMobile } from "o365.GlobalState.ts";
    import { OActionSaveCancel } from "o365-data-components";

    const emit = defineEmits(['cancel', 'new']);

    const showPinned = ref(false);
    const imageArchiveModal = ref();
    const sendReminderModal = ref();
    const assignedToHasMobileNo = ref(false);    
    const reminderText = ref("");
    const restrictPersonsLkpToPersonsWithAccess = ref(false);

    const props = defineProps({
        ownerid: { type: [Number, String], default: null },
        ownerField: { type: String, default: "Step_ID" },
        parentCommentId: { type: [Number, String] },
        parentCommentField: { type: String, default: "ParentComment_ID" },
        parentCommentParentField: { type: String, default: "ID" },
        dataobject: Object,
        rolesDataObject: {
            type: Object,
            required: false
        },
        disableLookupHovers: {
            type: Boolean,
            required: false,
            default: false
        },
        comment: Object,
        index: Number,
        allowEdit: Boolean,        
        personDataObject: Object,
        isVerification: Boolean,
        isNew: Boolean,
        showRestrictToPersonWithAccess:Boolean,
        sendEmailProcedure: { type: String, default: null },
        sendReminderProcedure : { type: String, default: null },
        showActions: { type: Boolean, default: true },
        isClientWorkspace: Boolean,
        showAttachmentsInSpotlight: { type: Boolean, default: false },
    });    

    watch(restrictPersonsLkpToPersonsWithAccess, allow => {
        if(allow){
                if(props.isClientWorkspace){
                _personDataObject.value.recordSource.whereClause = "AllPersons = 0";
            } else {
                _personDataObject.value.recordSource.whereClause = "AllPersons = 1";
            } 
        }else{
            if(props.isClientWorkspace){
                _personDataObject.value.recordSource.whereClause = "AllPersons = 0 AND CanUseClientWorkspace IS NOT NULL";
            } else {
                _personDataObject.value.recordSource.whereClause = "AllPersons = 0";
            } 
        }
       

        if (_personDataObject.value.state.isLoaded) {
            _personDataObject.value.load();
        }
    });

    const afterSaveCb = props.dataobject.on("AfterSave", () => {
        isEditing.value = false;
        isEditingActionResponse.value = false;
        isEditingVerificationResponse.value = false;
        isNew.value = false;
    });    

    const beforeCreateCb = props.dataobject.on("BeforeCreate", e => {        
        if (!e.values[props.ownerField]) {
            e.values[props.ownerField] = props.ownerid; 
        }                
    });

    const beforeUpdateCb = props.dataobject.on("BeforeUpdate", e => {
        if (e.values["CloseOutComment"] || e.values["CloseoutComment"]){
            e.values["Completed"] = new Date();
            e.values["CompletedBy_ID"] = userSession.personId;

            // Set Updated to be equal to its current value so that the field is not updated in trigger when we complete an action.
            if (e.values["PrimKey"] === _comment.value.PrimKey && _comment.value.Updated && _comment.value.UpdatedBy_ID) {
                e.values["Updated"] = _comment.value.Updated;
                e.values["UpdatedBy_ID"] = _comment.value.UpdatedBy_ID;
            }
        }
    });

    const changesCancelledCb = props.dataobject.on("ChangesCancelled", () => {
        isEditing.value = false;
        isEditingActionResponse.value = false;
        isEditingVerificationResponse.value = false;
        isNew.value = false;
    });

    function setRolesWhereClause(){
        if(props.isClientWorkspace){
            return "AND Capabilities LIKE '%Can use Client Workspace%'"
        } else {
            return "";
        } 
    }

    function setMobile(row) {
        assignedToHasMobileNo.value = row.MobileNo != null;
    }

    function addImages(selectedImages){
        selectedImages.forEach(x => {
            var obj = new Object();
            obj.FileName = x.FileName;
            obj.FileRef = x.FileRef;
            obj.FileSize = x.FileSize;
            obj[props.parentCommentField] = props.comment.ID ?? null;

            props.dataobject.createNew(obj, false);
        });
        props.dataobject.save();
        imageArchiveModal.value?.hide();
    }
    
    function addFromArchive(){
        imageArchiveModal.value?.show();
    }

    onUnmounted(() => {
        // Clean up data object event listeners if this JournalItem is removed.
        afterSaveCb && afterSaveCb();
        beforeCreateCb && beforeCreateCb();
        changesCancelledCb && changesCancelledCb();
        beforeUpdateCb && beforeUpdateCb();
    });

    async function pinComment(pin){
        _comment.value.Pinned = pin ? new Date() : null;
        await props.dataobject.save();
        props.dataobject.load();
    }

    function filterForComment(x) {
        return x[props.parentCommentParentField] === _comment.value[props.parentCommentParentField] && x.IsImage == _comment.value.IsImage;
    }

    const beforeUpload = (comment) => {
        var obj = new Object();
        obj[props.ownerField] = comment[props.ownerField];
        // obj[props.parentCommentField] = comment[props.parentCommentField];

        obj[props.parentCommentField] = comment.ID;

        // if (userSession.personId === 95778) {
            // debugger
        // }
        
        // console.log("props: ", props);
        // console.log("obj: ", obj);
        return obj;
    }

    const newComment = ref();
    const textArea = ref(null);
    const journalRef = ref(null);
    const _comment = computed({
        get() {
            return props.comment ?? newComment.value
        }, 
        set(value) 
        {
            newComment.value = value;
        }
    });

    // Some views call this column CloseoutComment and others CloseOutComment (note case differences)
    const _closeoutComment = computed({
        get() {
            return _comment.value.CloseoutComment || _comment.value.CloseOutComment
        },
        set(value) {            
            if (props.dataobject.fields["CloseoutComment"]) {
                _comment.value.CloseoutComment = value;
            } else {
                _comment.value.CloseOutComment = value;
            }
        }
    });

    const _personDataObject = computed(() => props.personDataObject);
    const canEditCompletedActionResponses = computed(() => userSession.personId === _comment.value.CompletedBy_ID && _comment.value.Completed);
    const canAdministrateItem = computed(() => _comment.value.CanAdministerComments || userSession.personId === _comment.value.CreatedBy_ID);
    const shouldShowUpdatedInfo = computed(() => {
        if (!_comment.value.Updated) {
            return false;
        }

        const updated = new Date(_comment.value.Updated);
        const completed = _comment.value.Completed && new Date(_comment.value.Completed);

        if (completed) {
            const diff = Math.abs(completed.getTime() - updated.getTime());
            return diff >= 2000;
        }

        const created = new Date(_comment.value.Created);
        const diff = Math.abs(updated.getTime() - created.getTime());
        return updated > created && diff >= 2000;
    });

    const isEditing = ref(false);
    const isEditingActionResponse = ref(false);
    const isEditingVerificationResponse = ref(false);
    const isNew = ref(props.isNew);
    const allowEdit = ref(props.allowEdit);
    const isVerification = computed(() => props.isVerification || _comment.value?.IsVerification);

    onMounted(() => {
        if(props.index == null){
            isEditing.value = true;
        }
    });

    if(!props.comment){
        props.dataobject.disableSaveOncurrentIndexChange = true
        props.dataobject.createNewAtTheEnd = false;

        var obj = new Object();
        obj[props.parentCommentField] = props.parentCommentId ?? null;
        obj.IsVerification = props.isVerification;
        obj[props.ownerField] = props.ownerid;

        const item = props.dataobject.createNew(obj, false);
        _comment.value = item;
    }

    function editItem(){
        isEditing.value = true;
    }

    function newItem(pObj){
        if(pObj.type === "verification"){
            journalRef.value.newItem(true);
        } else {
            journalRef.value.newItem(false);
        }
    }
    
    function doCancel(){
        props.dataobject.cancelChanges();
        emit('cancel');
    }

    function deleteComment(){
        props.dataobject.deleteItem(props.comment);
    }    

    function scrollIntoView(){
        textArea.value.activateEditor()
    }

    defineExpose({
        scrollIntoView
    });

    async function approveVerification() {
        _comment.value.Approved = new Date();
        _comment.value.Rejected = null;
        _comment.value.Completed = new Date();
        _comment.value.CompletedBy_ID = userSession.personId;
        await _comment.value.save().then(()=>{

        }).catch((e)=>{
            var vTmpValue = _closeoutComment.value;
            _comment.value.cancelChanges();
            _closeoutComment.value = vTmpValue;
        });
    }

    function rejectVerification() {
        if (_closeoutComment.value == null){
            return alert($t("Rejected verifications must have a closeout comment."), 'warning', { autohide: true, delay: 3000 })
        }        
        _comment.value.Rejected = new Date();
        _comment.value.Approved = null;
        _comment.value.Completed = new Date();
        _comment.value.CompletedBy_ID = userSession.personId;
        _comment.value.save();
    }

    const sendReminder = () =>{
        const procSendReminder = getOrCreateProcedure({ id:"procSendReminder", procedureName: props.sendReminderProcedure ??  "astp_Workflow_ItemActionReminder" });

        const url = new URL(location.href);
        const hostname = url.hostname;
        const parts = hostname.split('.');
        const subdomain = parts.length > 2 ? parts[0] : null;


        procSendReminder.execute({ Action_ID: _comment.value.ID, Domain: subdomain })
        .then((pData) => {            
            if(pData.Table[0].EmailBody){
                reminderText.value = pData.Table[0].EmailBody;
                sendReminderModal.value?.show();
            }
        });
    }
    
    const sendReminderConfirm = () =>{
        const procSendReminder = getOrCreateProcedure({ id:"procSendReminder", procedureName: props.sendReminderProcedure ??  "astp_Workflow_ItemActionReminder" });

        const url = new URL(location.href);
        const hostname = url.hostname;
        const parts = hostname.split('.');
        const subdomain = parts.length > 2 ? parts[0] : null;


        procSendReminder.execute({ Action_ID: _comment.value.ID, Domain: subdomain, Text: reminderText.value })
        .then((pData) => {
            alert('Reminder sent', 'success', { autohide: true, delay:2500});
            sendReminderModal.value?.hide();
        });
    }

    function showEmailDlg() {
        journalRef?.value?.showEmailDialog(props.ownerid);
    }
</script>

<style scoped>
    .multiline-comment {
        white-space: pre-wrap;
        overflow-wrap: break-word;
    }
</style>